.parent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    
    height: 91vh;
    width: 100vw;
    
    background-image: url("../public/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
#transactions{
        flex-direction: column;
}
#login{
    display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        background: rgba(176, 162, 162, 0.8);
        border-color: black;
        border-style: solid;
        border-width: 2px;
        border-radius: 50px;
        padding: 20px;
}

.evenly{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

thead {
    /* width: 100%; */
    text-align: center;
    vertical-align: middle;
}
td{
    text-align: center;
    vertical-align: middle;
    padding-left: 20px;
}